<template>

    <!---->
    <el-dialog v-if="this.leisureGame != null"
            title="미니게임 결과입력"
            :visible.sync="dialogVisible"
            width="50%"
            center
            @close='close'>
        <div style="text-align: center">
            {{leisureGame.kindConfig.kindName}} - {{leisureGame.sequence}} 회차 결과입력
        </div>
        <!--파워볼 결과-->
        <div style="text-align: center"
                v-if="leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_POWERBALL
                                                ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_1M
                                                 ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_3M
                                                 ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_5M
                                                 ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_3M
                                                 ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_5M">


            <el-input size="mini" clearable  v-model="leisureGame.powerGameResult.ball1" style="width: 100px; margin: 0 3px;border: 1px solid red"
                      class="" placeholder="일반볼 1"></el-input>

            <el-input size="mini" clearable  v-model="leisureGame.powerGameResult.ball2" style="width: 100px; margin: 0 3px;border: 1px solid red"
                   class="" placeholder="일반볼 2"></el-input>

            <el-input size="mini" clearable v-model="leisureGame.powerGameResult.ball3" style="width: 100px; margin: 0 3px;border: 1px solid red"
                   class="" placeholder="일반볼 3"></el-input>

            <el-input size="mini" clearable v-model="leisureGame.powerGameResult.ball4" style="width: 100px; margin: 0 3px;border: 1px solid red"
                   class="" placeholder="일반볼 4"></el-input>

            <el-input size="mini" clearable v-model="leisureGame.powerGameResult.ball5" style="width: 100px; margin: 0 3px;border: 1px solid red"
                   class="" placeholder="일반볼 5"></el-input>

            <el-input size="mini" clearable v-model="leisureGame.powerGameResult.powerball" style="width: 100px; border: 1px solid blue"
                   class="" placeholder="파워볼"></el-input>
        </div>
        <!--사다리-->
        <div style="text-align: center;margin-top: 10px"
             v-if="kind == managerConst.LeisureGame.LEISURE_KIND_STAR_SD_1
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_STAR_SD_2
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_STAR_SD_3
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_SADALI
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_SPEEDSADALI
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_DALIDALI">
            <div>
                <el-radio border v-model="leisureGame.miniGameResult.oddeven"
                          :label="managerConst.LeisureGame.RESULT_ODD">홀
                </el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.oddeven"
                          :label="managerConst.LeisureGame.RESULT_EVEN">짝
                </el-radio>
            </div>
            <div style="margin-top: 5px">
                <el-radio border v-model="leisureGame.miniGameResult.lineCount"
                          :label="managerConst.LeisureGame.RESULT_LINE_COUNT_3">3줄
                </el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.lineCount"
                          :label="managerConst.LeisureGame.RESULT_LINE_COUNT_4">4줄
                </el-radio>
            </div>
            <div style="margin-top: 5px">
                <el-radio border v-model="leisureGame.miniGameResult.leftorright"
                          :label="managerConst.LeisureGame.RESULT_START_LEFT">좌촐발
                </el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.leftorright"
                          :label="managerConst.LeisureGame.RESULT_START_RIGHT">우출발
                </el-radio>
            </div>
        </div>

        <!--Bet365-->
        <div style="text-align: center;margin-top: 10px;display: flex;justify-content: center;align-items: center"
             v-if="leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP
        || leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE
        || leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_EUROCUP
        || leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_WORLDCUP
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_WARTER
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_BAKER">

            <el-input type="text" style="width: 200px"
                      v-model="leisureGame.miniGameResult.homeTeamScore" :placeholder="leisureGame.homeTeamName"></el-input>
            vs
            <el-input type="text" style="width: 200px"
                      v-model="leisureGame.miniGameResult.awayTeamScore" :placeholder="leisureGame.awayTeamName"></el-input>
        </div>
        <!--개경주,경마-->
        <div style="text-align: center;margin-top: 10px"
             v-if="leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK
        || leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_2
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_3
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_4">
            <div class="resultradio">
                <el-radio border v-model="leisureGame.miniGameResult.first" label="1">1번</el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.first" label="2">2번</el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.first" label="3">3번</el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.first" label="4">4번</el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.first" label="5">5번</el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.first" label="6">6번</el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.first" label="7">7번</el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.first" label="8">8번</el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.first" label="9">9번</el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.first" label="10">10번</el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.first" label="11">11번</el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.first" label="12">12번</el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.first" label="13">13번</el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.first" label="14">14번</el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.first" label="15">15번</el-radio>
            </div>
        </div>

        <!--달팽이-->
        <div style="text-align: center;margin-top: 10px"
             v-if="kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_SNAIL">
            <div>
                <el-radio border v-model="leisureGame.miniGameResult.oddeven"
                          :label="managerConst.LeisureGame.RESULT_ODD">홀
                </el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.oddeven"
                          :label="managerConst.LeisureGame.RESULT_EVEN">짝
                </el-radio>
            </div>
            <div style="margin-top: 5px">
                <el-radio border v-model="leisureGame.miniGameResult.overunder"
                          :label="managerConst.LeisureGame.RESULT_OVER">오버
                </el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.overunder"
                          :label="managerConst.LeisureGame.RESULT_UNDER">언더
                </el-radio>
            </div>
            <div style="margin-top: 5px">
                <el-radio border v-model="leisureGame.miniGameResult.winplayer"
                          label="1">1번팽이
                </el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.winplayer"
                          label="2">2번팽이
                </el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.winplayer"
                          label="3">3번팽이
                </el-radio>
                <el-radio border v-model="leisureGame.miniGameResult.winplayer"
                          label="4">4번팽이
                </el-radio>
            </div>
        </div>

        <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">취소</el-button>
                <el-button type="primary" @click="saveManualResult">확인</el-button>
            </span>
    </el-dialog>

</template>

<script>
    import managerConst from "../../common/administrator/managerConst";
    import {saveManualResult} from "../../network/manager/leisureRequest";

    export default {
        name: "LeisureGameInsertResultComp",
        data() {
            return {
                managerConst: managerConst,
                leisureGame: null,
                kind: 0,
                dialogVisible : false,
            }
        },
        props: {
            lgame: {
                type: Object,
                default() {
                    return {}
                }
            },
            insertResultDialogVisible: {
                type: Boolean,
                default() {
                    return false
                }
            }
        },
        methods:{
            close(){
            },
            saveManualResult(){
                saveManualResult(this.leisureGame).then(res=>{
                    if(res.data.success){
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: '미니게임 결과입력이 완료되였습니다'
                        });
                        this.$emit('saveManualResultSuccess')
                    }else {
                        this.$message({
                            type: 'error',
                            duration: 1000,
                            message: res.data.msg
                        });
                    }
                    this.leisureGame=null;
                })

            },
        },
        created() {
            this.leisureGame.id = this.lgame.id
            this.leisureGame.kind = this.lgame.kind
            this.leisureGame.sequence = this.lgame.sequence
            this.leisureGame.kindConfig = this.lgame.kindConfig
            this.leisureGame.homeTeamName = this.lgame.homeTeamName
            this.leisureGame.awayTeamName = this.lgame.awayTeamName
            this.kind = this.leisureGame.kind
        },
        watch: {
            lgame: {
                handler(newVal) {
                    this.leisureGame = {'miniGameResult': {},'powerGameResult':{}}
                    this.leisureGame.id = newVal.id
                    this.leisureGame.kind = newVal.kind
                    this.leisureGame.sequence = newVal.sequence
                    this.leisureGame.homeTeamName = newVal.homeTeamName
                    this.leisureGame.awayTeamName = newVal.awayTeamName
                    this.leisureGame.kindConfig = newVal.kindConfig
                    this.kind = newVal.kind
                },
                immediate: true,
                deep: true
            },
            insertResultDialogVisible: {
                handler(newVal) {
                   this.dialogVisible =  newVal
                },
                immediate: true,
                deep: false
            }
        }

    }
</script>

<style scoped>
    .resultradio {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap
    }

    .resultradio .el-radio {
        width: 25%;
        margin: 3px;
    }
</style>