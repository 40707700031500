<template>
    <div>
        <!--하운슬로 파워볼-->
        <el-select size="mini" style="width: 180px;margin-right: 5px"
                   v-model="kind" placeholder=""
                   @change="changeLeisureKind"
                   v-if="kind == managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_3M
                   || kind == managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_5M">
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_3M" label="하운슬로 파워볼 3분"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_5M" label="하운슬로 파워볼 5분"></el-option>
        </el-select>
        <!--엔트리 EOS 파워볼-->
        <el-select size="mini" style="width: 180px;margin-right: 5px"
                   v-model="kind" placeholder=""
                   @change="changeLeisureKind"
                   v-if="kind == managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_1M
                   || kind == managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_3M
                   || kind == managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_5M">
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_1M" label="EOS 파워볼 1분"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_3M" label="EOS 파워볼 3분"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_5M" label="EOS 파워볼 5분"></el-option>
        </el-select>

        <!--Bet365 가상축구-->
        <el-select size="mini" style="width: 180px;margin-right: 5px"
                   v-model="kind" placeholder=""
                   @change="changeLeisureKind"
                   v-if="kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_EUROCUP
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_WORLDCUP">
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP" label="가상축구 - 프리미어"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_EUROCUP" label="가상축구 - 유로컵"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE" label="가상축구 - 슈퍼리그"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_WORLDCUP" label="가상축구 - 월드컵"></el-option>
        </el-select>

        <!--Bet365 가상농구-->
        <el-select size="mini" style="width: 180px;margin-right: 5px"
                   v-model="kind" placeholder=""
                   @change="changeLeisureKind"
                   v-if="kind == managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_WARTER
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_BAKER">
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_WARTER" label="가상농구 - 워터포드"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_BAKER" label="가상농구 - 베이커"></el-option>
        </el-select>

        <!--Bet365 가상개경주-->
        <el-select size="mini" style="width: 180px;margin-right: 5px"
                   v-model="kind" placeholder=""
                   @change="changeLeisureKind"
                   v-if="kind == managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK">
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK" label="가상개경주 - 골든힐"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK" label="가상개경주 - 힐사이드"></el-option>
        </el-select>

        <!--Bet365 가상경마-->
        <el-select size="mini" style="width: 180px;margin-right: 5px"
                   v-model="kind" placeholder=""
                   @change="changeLeisureKind"
                   v-if="kind == managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_2
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_3
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_4">
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_2" label="가상경마 - 브리타니아"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_3" label="가상경마 - 페스티발"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_4" label="가상경마 - 픽토리아"></el-option>
        </el-select>

        <!--별다리-->
        <el-select size="mini" style="width: 180px;margin-right: 5px"
                   v-model="kind" placeholder=""
                   @change="changeLeisureKind"
                   v-if="kind == managerConst.LeisureGame.LEISURE_KIND_STAR_SD_1
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_STAR_SD_2
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_STAR_SD_3">
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_STAR_SD_1" label="별다리 1분"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_STAR_SD_2" label="별다리 2분"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_STAR_SD_3" label="별다리 3분"></el-option>
        </el-select>

        <!--크라운-->
        <el-select size="mini" style="width: 180px;margin-right: 5px"
                   v-model="kind" placeholder=""
                   @change="changeLeisureKind"
                   v-if="kind == managerConst.LeisureGame.LEISURE_KIND_SUTDA
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_NINEBALL">
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_SUTDA" label="크라운 섯다"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_NINEBALL" label="크라운 나인볼"></el-option>
        </el-select>
        <!--스카이파크-->
        <el-select size="mini" style="width: 180px;margin-right: 5px"
                   v-model="kind" placeholder=""
                   @change="changeLeisureKind"
                   v-if="kind == managerConst.LeisureGame.LEISURE_KIND_SKY_SPEED_BAKARA
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_SKY_ODDEVEN
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_SKY_DICE">
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_SKY_SPEED_BAKARA" label="스카이파크 바카라"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_SKY_ODDEVEN" label="스카이파크 홀짝"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_SKY_DICE" label="스카이파크 주사위"></el-option>
        </el-select>
        <!--로투스-->
        <el-select size="mini" style="width: 180px;margin-right: 5px"
                   v-model="kind" placeholder=""
                   @change="changeLeisureKind"
                   v-if="kind == managerConst.LeisureGame.LEISURE_KIND_BAKARA_LOTUS_1
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_ODDEVEN_LOTUS
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_LOTUS_DRAGONTIGER">
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BAKARA_LOTUS_1" label="로투스 바카라"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_ODDEVEN_LOTUS" label="로투스 홀짝"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_LOTUS_DRAGONTIGER" label="로투스 드래곤타이거"></el-option>
        </el-select>

        <!--네임드-->
        <el-select size="mini" style="width: 180px;margin-right: 5px"
                   v-model="kind" placeholder=""
                   @change="changeLeisureKind"
                   v-if="kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_SADALI
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_SPEEDSADALI
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_SNAIL
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_DALIDALI
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_FX_1M
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_FX_2M
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_FX_3M
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_FX_4M
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_FX_5M">
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_NAMED_SADALI" label="네임드 사다리"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_NAMED_SPEEDSADALI" label="네임드 스피드 사다리"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_NAMED_DALIDALI" label="네임드 다리다리"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_NAMED_SNAIL" label="네임드 달팽이"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_FX_1M" label="FX 1분"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_FX_2M" label="FX 2분"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_FX_3M" label="FX 3분"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_FX_4M" label="FX 4분"></el-option>
            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_FX_5M" label="FX 5분"></el-option>
        </el-select>
    </div>

</template>

<script>
    import managerConst from "../../common/administrator/managerConst";

    export default {
        name: "LeisuregameSelectorComp",
        data() {
            return {
                selectedStatus: 'open',
                kind: 0,
                managerConst
            }
        },
        props: {
            pkind: {
                type: Number,
                default() {
                    return 0;
                }
            },

        },
        methods: {
            changeLeisureKind() {
                this.$emit('changeLeisureKind',this.kind)
            }
        },
        created() {
            this.kind = this.pkind
        },
        watch:{
            pkind(newVal) {
                this.kind = newVal
            }
        }
    }
</script>

<style scoped>

</style>