<template>
    <div>
        <!--파워볼 결과-->
        <div v-if="leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_POWERBALL
                                                ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_1M
                                                 ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_3M
                                                 ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_5M
                                                 ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_3M
                                                 ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_5M">
            <span style="color: blue">파워볼</span>(
            <span>{{leisureGame.leisureGameResult.pbOddevenText}}</span>,
            <span>{{leisureGame.leisureGameResult.pbOverunderText}}</span>)
            <span style="color: red">일반볼</span>(
            <span>{{leisureGame.leisureGameResult.nbOddevenText}}</span>,
            <span>{{leisureGame.leisureGameResult.nbOverunderText}}</span>,
            <span>{{leisureGame.leisureGameResult.nbBmsText}}</span>)
        </div>

        <!--사다리-->
        <div v-if="leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_POWERSADALI
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_DALIDALI
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_KINOSADALI
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_SADALI
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_SPEEDSADALI
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_DALIDALI
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_STAR_SD_1
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_STAR_SD_2
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_STAR_SD_3">
            <span class="">{{leisureGame.leisureGameResult.oddevenText}},</span>
            <span class="">{{leisureGame.leisureGameResult.lineCountText}},</span>
            <span class="">{{leisureGame.leisureGameResult.leftorrightText}}</span>
        </div>


        <!--Bet365-->
        <div v-if="leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP
        || leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE
        || leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_EUROCUP
        || leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_WORLDCUP
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_WARTER
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_BAKER">
            <span>{{leisureGame.homeTeamScore + ':'+leisureGame.awayTeamScore}}</span>
        </div>
        <!--Bet365-->
        <div v-if="leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK
        || leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_2
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_3
        ||leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_4">
          <span>{{leisureGame.leisureGameResult.first +'번'}}</span>
        </div>
        <!--크라운 섯다-->
        <div v-if="leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_SUTDA">
            <span>{{leisureGame.leisureGameResult.crownWinnerText}}</span>
        </div>
        <!--크라운 나인볼-->
        <div v-if="leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_NINEBALL">
            <span>{{leisureGame.leisureGameResult.nbWinnerText}},</span>
            <span>{{'Left:'+leisureGame.leisureGameResult.nbleft}},</span>
            <span>{{'Right:'+leisureGame.leisureGameResult.nbright}},</span>
            <span>{{'색상:' + leisureGame.leisureGameResult.nbr2color}},</span>
            <span>{{'홀짝:'+leisureGame.leisureGameResult.oddeven}}</span>
        </div>

        <!--로투스 바카라-->
        <div v-if="leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_BAKARA_LOTUS_1">
            <span>{{leisureGame.leisureGameResult.lotusBakaraWinnerText}},</span>
            <span>{{leisureGame.leisureGameResult.lotusBakaraPBNumber}}</span>
        </div>
        <!--로투스 홀짝-->
        <div v-if="leisureGame.kind ==  managerConst.LeisureGame.LEISURE_KIND_ODDEVEN_LOTUS">
            <span>{{leisureGame.leisureGameResult.oddevenText}}</span>
            <span>{{leisureGame.leisureGameResult.overunderText}},</span>
            <span>{{leisureGame.leisureGameResult.lotusCardColorText}},</span>
            <span>{{leisureGame.leisureGameResult.lotusCardTypeText}},</span>
            <span>{{'히든카드: '+leisureGame.leisureGameResult.lotusCard2}}</span>
        </div>
        <!--로투스 드래곤 타이거-->
        <div v-if="leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_LOTUS_DRAGONTIGER">
            <span>{{leisureGame.leisureGameResult.winplayerText}},</span>
            <span >{{leisureGame.leisureGameResult.lotusBakaraPBNumber }}</span>
        </div>
        <!--스카이 스피드 바카라-->
        <div v-if="leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_SKY_SPEED_BAKARA">
            <span>{{leisureGame.leisureGameResult.skySpeedBakaraWinnerText}}</span>
        </div>
        <!--스카이홀짝-->
        <div v-if="leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_SKY_ODDEVEN">
            <span>{{leisureGame.leisureGameResult.oddevenText}},</span>
            <span>{{leisureGame.leisureGameResult.skyCardTypeText}}</span>
        </div>
        <!--스카이 주사위-->
        <div v-if="leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_SKY_DICE">
            <span>{{leisureGame.leisureGameResult.oddevenText}},</span>
            <span>{{leisureGame.leisureGameResult.skyoverunderText}},</span>
            <span>{{leisureGame.leisureGameResult.skywincolorText}}</span>
        </div>
        <!--MGM바카라-->
        <div v-if="leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_MGM_BAKARA">
            <span>{{leisureGame.leisureGameResult.mgmBakaraWinnerText}}</span>
        </div>
        <!--MGM홀짝-->
        <div v-if="leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_MGM_ODDEVEN">
             <span>{{leisureGame.leisureGameResult.mgmBakaraWinnerText}}</span>
        </div>

        <!--Fx-->
        <div v-if="leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_FX_1M
        || leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_FX_2M
        || leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_FX_3M
        || leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_FX_4M
        || leisureGame.kind ==managerConst.LeisureGame.LEISURE_KIND_FX_5M">
             <span>{{leisureGame.leisureGameResult.oddevenText}},</span>
            <span>{{leisureGame.leisureGameResult.overunderText}},</span>
            <span>{{'종가 '+leisureGame.leisureGameResult.jgSellbuyText}},</span>
            <span>{{'시가 '+leisureGame.leisureGameResult.cgSellbuyText}}</span>
        </div>

        <!--달팽이-->
        <div v-if="leisureGame.kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_SNAIL">
            <span>{{leisureGame.leisureGameResult.oddevenText}},</span>
            <span>{{leisureGame.leisureGameResult.overunderText}},</span>
            <span>{{leisureGame.leisureGameResult.winplayerText + '번팽이'}}</span>
        </div>

    </div>
</template>

<script>
    import managerConst from "../../common/administrator/managerConst";

    export default {
        name: "LeisureGameResultTextComp",
        data() {
            return {
                managerConst: managerConst,
                leisureGame: {}
            }
        },
        props: {
            pgame: {
                type: Object,
                default() {
                    return {};
                }
            },

        },
        created() {
        },
        watch: {
            pgame: {
                handler(newVal) {
                    this.leisureGame={}
                    this.leisureGame = newVal
                },
                immediate: true,
                deep: true
            },
        }

    }
</script>

<style scoped>

</style>