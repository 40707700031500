var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_3M
               || _vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_5M)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_3M,"label":"하운슬로 파워볼 3분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_5M,"label":"하운슬로 파워볼 5분"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_1M
               || _vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_3M
               || _vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_5M)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_1M,"label":"EOS 파워볼 1분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_3M,"label":"EOS 파워볼 3분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_5M,"label":"EOS 파워볼 5분"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_EUROCUP
               ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE
               ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP
               ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_WORLDCUP)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP,"label":"가상축구 - 프리미어"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_EUROCUP,"label":"가상축구 - 유로컵"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE,"label":"가상축구 - 슈퍼리그"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_WORLDCUP,"label":"가상축구 - 월드컵"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_WARTER
               ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_BAKER)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_WARTER,"label":"가상농구 - 워터포드"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_BAKER,"label":"가상농구 - 베이커"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK
               ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK,"label":"가상개경주 - 골든힐"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK,"label":"가상개경주 - 힐사이드"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_2
               ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_3
                ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_4)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_2,"label":"가상경마 - 브리타니아"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_3,"label":"가상경마 - 페스티발"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_4,"label":"가상경마 - 픽토리아"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_STAR_SD_1
               ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_STAR_SD_2
                ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_STAR_SD_3)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_STAR_SD_1,"label":"별다리 1분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_STAR_SD_2,"label":"별다리 2분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_STAR_SD_3,"label":"별다리 3분"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_SUTDA
               ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_NINEBALL)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_SUTDA,"label":"크라운 섯다"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_NINEBALL,"label":"크라운 나인볼"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_SKY_SPEED_BAKARA
               ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_SKY_ODDEVEN
               ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_SKY_DICE)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_SKY_SPEED_BAKARA,"label":"스카이파크 바카라"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_SKY_ODDEVEN,"label":"스카이파크 홀짝"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_SKY_DICE,"label":"스카이파크 주사위"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BAKARA_LOTUS_1
               ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_ODDEVEN_LOTUS
               ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_LOTUS_DRAGONTIGER)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BAKARA_LOTUS_1,"label":"로투스 바카라"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_ODDEVEN_LOTUS,"label":"로투스 홀짝"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_LOTUS_DRAGONTIGER,"label":"로투스 드래곤타이거"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_NAMED_SADALI
                ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_NAMED_SPEEDSADALI
                ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_NAMED_SNAIL
                ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_NAMED_DALIDALI
                ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_FX_1M
                ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_FX_2M
                ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_FX_3M
                ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_FX_4M
                ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_FX_5M)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_NAMED_SADALI,"label":"네임드 사다리"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_NAMED_SPEEDSADALI,"label":"네임드 스피드 사다리"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_NAMED_DALIDALI,"label":"네임드 다리다리"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_NAMED_SNAIL,"label":"네임드 달팽이"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_FX_1M,"label":"FX 1분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_FX_2M,"label":"FX 2분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_FX_3M,"label":"FX 3분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_FX_4M,"label":"FX 4분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_FX_5M,"label":"FX 5분"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }